/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* a:hover,
a:focus {
  text-decoration: underline;
} */
@font-face {
  font-family: rubik;
  src: url(assets/fonts/Rubik-VariableFont_wght.ttf);
}
@font-face {
  font-family: roboto;
  src: url(assets/fonts/Roboto-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: roboto;
  src: url(assets/fonts/Roboto-Bold.ttf);
  font-weight: 700;
}

html {
  box-sizing: border-box;
}

:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: roboto, rubik;
}
